import http from "./axios";

export const getAllWatchedLeadsService = async () => {
  return await http.get(
    `/api/client_leads/?is_favorite=true&status=open&limit=99999`
  );
};

export const getAllQualifiedLeadsService = async () => {
  return await http.get(
    `/api/client_leads/?status=open&is_favorite=false&limit=99999`
  );
};

export const syncClientLeadsService = async (id: number, leadId = null) => {
  return await http.post(`/api/clients/${id}/sync/?lead_id=${leadId}`);
};
