import React from "react";
import ContentLoader from "react-content-loader";

type CompanyInfoDataLoaderProps = {
  isOnPageLoading: boolean;
  children: any;
};

const CompanyInfoDataLoader = ({
  isOnPageLoading,
  children,
}: CompanyInfoDataLoaderProps) => {
  return (
    <>
      {children}
      {isOnPageLoading && (
        <ContentLoader
          speed={2}
          width={50}
          height={28}
          viewBox="0 0 50 18"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="3" ry="3" width="178" height="34" />
        </ContentLoader>
      )}
    </>
  );
};

export default CompanyInfoDataLoader;
