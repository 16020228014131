import React from "react";
import CompanyInfoData from "./CompanyInfoDataLoader";

type CompanyDetailsProps = {
  isOnPageLoading: boolean;
  leadShow: any;
};

const CompanyDetails = ({ isOnPageLoading, leadShow }: CompanyDetailsProps) => {
  return (
    <div className="card mb-3">
      <div className="card-header">
        <h5 className="my-2">Company Details</h5>
      </div>

      <table className="table">
        <tbody>
          {/*<tr>
            <th scope="row">Industry</th>
            <td>
              <CompanyInfoData isOnPageLoading={isOnPageLoading}>
                {!isOnPageLoading &&
                  (leadShow?.company?.company_industry || "")}
              </CompanyInfoData>
            </td>
          </tr>*/}
          <tr>
            <th scope="row">Years In Business</th>
            <td>
              <CompanyInfoData isOnPageLoading={isOnPageLoading}>
                {!isOnPageLoading &&
                  (leadShow?.company?.estimated_age
                    ? leadShow.company.estimated_age + " years"
                    : "-")}
              </CompanyInfoData>
            </td>
          </tr>
          <tr>
            <th scope="row">Company Revenue</th>
            <td>
              <CompanyInfoData isOnPageLoading={isOnPageLoading}>
                {!isOnPageLoading && (leadShow?.company?.revenue_band || "0")}
              </CompanyInfoData>
            </td>
          </tr>
          <tr>
            <th scope="row">Employee Count</th>
            <td>
              <CompanyInfoData isOnPageLoading={isOnPageLoading}>
                {!isOnPageLoading && (leadShow?.company?.employee_band || "0")}
              </CompanyInfoData>
            </td>
          </tr>
          {/*<tr>
            <th scope="row">Alexa Rank</th>
            <td
              data-countup={`{"count":${
                leadShow?.company?.alexa_rank || 0
              },"format":"comma","prefix":""}`}
            >
              {!isOnPageLoading && (
                <CountUp
                  className="account-balance"
                  start={0}
                  end={leadShow?.company?.alexa_rank || 0}
                  duration={2.75}
                  separator=","
                  decimal=","
                >
                  {({ countUpRef, start }) => (
                    <div>
                      <span ref={countUpRef} />
                    </div>
                  )}
                </CountUp>
              )}
              {isOnPageLoading && (
                <ContentLoader
                  speed={2}
                  width={50}
                  height={28}
                  viewBox="0 0 50 18"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="3" ry="3" width="178" height="34" />
                </ContentLoader>
              )}
            </td>
          </tr>*/}
        </tbody>
      </table>
    </div>
  );
};

export default CompanyDetails;
