import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { syncClientLeadsService } from "../../service/client-leads.service";
import { devLogError } from "../../helpers/utils";
import { load } from "../../utils/storage";

const SyncButton = ({ leadId = null }: any) => {
  const [isLoading, setIsLoading] = useState<any>({
    button: false,
  });

  const onClick = async () => {
    let _profile = await load("profile");
    if (!_profile) return;

    setIsLoading((prev: any) => ({ ...prev, button: true }));

    try {
      let res = await syncClientLeadsService(_profile?.client_id, leadId);
      if (res?.data) {
        window.location.reload();
        setIsLoading((prev: any) => ({ ...prev, button: false }));
      }
    } catch ({ response }) {
      devLogError(response);
      setIsLoading((prev: any) => ({ ...prev, button: false }));
    }
  };

  return (
    <Button
      type="button"
      variant="primary"
      id="sync-button"
      size="lg"
      onClick={onClick}
      disabled={isLoading?.button}
      style={{
        position: "fixed",
        bottom: 20,
        zIndex: 9999,
        right: 10,
        color: "#FFF",
        textAlign: "center",
        boxShadow: "2px 2px 3px #999",
      }}
    >
      Refresh {leadId ? "Lead" : "Data"}{" "}
      {isLoading?.button ? (
        <Spinner
          animation="border"
          variant="light"
          size="sm"
          className="mx-1"
        />
      ) : (
        <FontAwesomeIcon
          icon={faArrowsRotate}
          size="xs"
          className="svg-white"
        />
      )}
    </Button>
  );
};

export default SyncButton;
