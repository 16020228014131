import { useEffect, useLayoutEffect, useState } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { Button, Form, Image, Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import moment from "moment";

// services
import http from "service/axios";
import { getOnboardingStatus } from "../../../service/onboarding.service";

// images
import LogoIcon from "../../../assets/img/logo/leadresolution-logo.png";
import { getUserProfile } from "../../../service/user.service";
import { devLog, devLogError } from "../../../helpers/utils";

const Login = () => {
  let history = useNavigate();
  const [lifecycleState, setLifecycleState] = useState<string>("onboarding");
  const [loading, setLoading] = useState<any>(false);
  const {
    register,
    handleSubmit,
    setError,
    watch,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm();

  let navigate = useNavigate();

  const isAuthenticated = () => {
    return localStorage.getItem("is_authenticated") === "ok";
  };

  useEffect(() => {
    if (isAuthenticated()) {
      // navigate("/");
      window.location.href = "/";
    }
  }, [navigate]);

  useLayoutEffect(() => {
    updateDOM();
  }, []);

  const updateDOM = () => {
    // hide the footer in login page
    let _mainFooter: any = document.querySelectorAll("#main-footer");
    devLog("test _mainFooter", _mainFooter);
    if (_mainFooter?.length > 0) {
      _.forEach(_mainFooter, (i: any) => {
        i.className += "d-none";
      });
    }
  };

  const doSubmit = async (data: any) => {
    devLog(data);
    setLoading(true);
    //
    try {
      let response = await http.post("/token/", data);
      devLog(response);
      if (response?.data) {
        localStorage.setItem("is_authenticated", "ok");
        localStorage.setItem("token", response.data.access);
        localStorage.setItem("refresh", response.data.refresh);
        // navigate("/");
        devLog("test lifecycleState", lifecycleState);
        /*if (lifecycleState === "onboarding") {
          history("/onboarding");
          return;
        }
        window.location.href = "/";
        setLoading(false);*/
        try {
          let onboardingRes = await getOnboardingStatus();
          devLog("test ", onboardingRes?.data);
          if (onboardingRes?.data) {
            GetUserProfile(onboardingRes?.data);
          }
        } catch ({ response }) {
          devLogError(response);
          setLoading(false);
        }
      }
    } catch ({ response }) {
      devLogError(response);
      setError("username", {
        type: "manual",
        message: "Incorrect username or password.",
      });
      setLoading(false);
    }
  };

  const GetUserProfile = async (onboardingRes: any) => {
    try {
      let response = await getUserProfile();
      if (response?.data) {
        // localStorage.setItem("profile", JSON.stringify(response.data));
        setLifecycleState(onboardingRes?.debug_lifecycle_state);
        if (onboardingRes?.debug_lifecycle_state === "onboarding") {
          localStorage.setItem(
            "onboarding_status",
            onboardingRes?.debug_lifecycle_state
          );
          localStorage.setItem(
            "login-time",
            "UTC: " + moment.utc().format("dddd, MMMM Do YYYY, h:mm:ss a")
          );
          window.location.href = `/onboarding/?client_id=${response.data?.client_id}&menu=general`;
        } else {
          window.location.href = "/";
        }

        setLoading(false);
      }
    } catch (e: any) {
      devLogError(e.response);
      setLoading(false);
    }
  };

  return (
    <div className="row flex-center min-vh-75 py-6">
      <Helmet>
        <title>Lead Resolution - Login</title>
      </Helmet>

      {!isAuthenticated() && (
        <div className="col-sm-10 col-md-8 col-lg-6 col-xl-4">
          <a className="d-flex flex-center mb-4" href={"/login"}>
            <Image className="mr-2" src={LogoIcon} alt="logo" width="250" />
          </a>
          <div className="card">
            <div className="card-body p-4 p-sm-5">
              {/*Alerts*/}
              {/*{% include 'partials/_alerts.html' %}*/}
              <div className="row text-left justify-content-center align-items-center mb-2">
                <div className="col-auto text-center">
                  <h3>Log in</h3>
                </div>
              </div>
              <Form
                //action={{% url 'login'%}}
                // method="POST"
                onSubmit={handleSubmit(doSubmit)}
                noValidate
                aria-disabled={loading}
              >
                <Form.Group className="mb-3" controlId="username">
                  <Form.Control
                    type="email"
                    placeholder="Email address*"
                    {...register("username", {
                      required: "This is required field.",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="username"
                    render={({ message }) => (
                      <div className="union-error-message invalid-feedback">
                        {message}
                      </div>
                    )}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="password">
                  <Form.Control
                    type="password"
                    placeholder="Password*"
                    {...register("password", {
                      required: "This is required field.",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="password"
                    render={({ message }) => (
                      <div className="union-error-message invalid-feedback">
                        {message}
                      </div>
                    )}
                  />
                </Form.Group>
                <div className="row justify-content-between align-items-center">
                  <div className="col-auto">
                    <div className="custom-control custom-checkbox">
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        id="basic-checkbox"
                        checked={true}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="basic-checkbox"
                      >
                        Remember me
                      </label>
                    </div>
                  </div>
                  <div className="col-auto">
                    <a className="fs--1" href={"/password/reset"}>
                      Forgot Password?
                    </a>
                  </div>
                </div>
                <div className="form-group">
                  {/*<button
                          className="btn btn-falcon-default d-block w-100 mt-3 action-button"
                          type="submit"
                          name="submit"
                        >
                          Log in
                        </button>*/}
                  <Button
                    variant="primary"
                    className="btn btn-falcon-default d-block w-100 mt-3 action-button text-white"
                    type="submit"
                    name="submit"
                  >
                    {loading ? (
                      <Spinner
                        animation="border"
                        variant="light"
                        size="sm"
                        className="mx-2"
                      />
                    ) : (
                      "Log in"
                    )}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
