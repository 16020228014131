import React from "react";
import DefaultIcon from "../../../assets/img/logo/logo-only.jpeg";
import ContentLoader from "react-content-loader";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactComponent as EnvelopeIcon } from "../../../assets/svg/regular-envelope.svg";
import { ReactComponent as LinkedinIcon } from "../../../assets/svg/linkedin.svg";
import { checkHttp } from "../../../helpers/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

type ProfileWithImageProps = {
  isOnPageLoading: boolean;
  leadShow: any;
};

const ProfileWithImage = ({
  isOnPageLoading,
  leadShow,
}: ProfileWithImageProps) => {
  return (
    <div className="card mb-3 h-100">
      <div className="row">
        <div className="col-sm-12 text-center my-4">
          {!isOnPageLoading && (
            <img
              className="rounded-circle img-thumbnail shadow-sm"
              src={`https://logo.clearbit.com/${leadShow?.company?.url}?size=600`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = DefaultIcon;
              }}
              width="200"
              style={{ padding: 10, alignContent: "center" }}
              alt="company"
              decoding="async"
            />
          )}
          {isOnPageLoading && (
            <ContentLoader
              speed={2}
              width={200}
              height={200}
              // viewBox="0 0 476 124"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <circle cx="100" cy="100" r="100" />
            </ContentLoader>
          )}
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h4
              className="mb-1 text-black-50 fw-bolder"
              // style={{ color: "#697586" }}
            >
              {!isOnPageLoading && (
                <>
                  {leadShow?.first_name} {leadShow?.last_name}
                </>
              )}
              {isOnPageLoading && (
                <ContentLoader
                  speed={2}
                  width="100%"
                  height={28}
                  // viewBox="0 0 50 18"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="3" ry="3" width="50%" height="34" />
                </ContentLoader>
              )}
            </h4>
            <h6 className="text-muted">
              {!isOnPageLoading && leadShow?.company?.name}
              {isOnPageLoading && (
                <ContentLoader
                  speed={2}
                  width="100%"
                  height={28}
                  // viewBox="0 0 50 18"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="3" ry="3" width="50%" height="34" />
                </ContentLoader>
              )}
            </h6>
            <h6>
              {!isOnPageLoading && leadShow?.title}
              {isOnPageLoading && (
                <ContentLoader
                  speed={2}
                  width="100%"
                  height={28}
                  // viewBox="0 0 50 18"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="3" ry="3" width="50%" height="34" />
                </ContentLoader>
              )}
            </h6>
          </div>
        </div>
        <div className="fs-xl-0" style={{ paddingTop: "2%", fontWeight: 600 }}>
          {!isOnPageLoading && (
            <div className="d-flex flex-row justify-content-evenly align-items-center">
              {leadShow?.email ? (
                <>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{leadShow?.email}</Tooltip>}
                  >
                    <Button
                      variant="light"
                      className="rounded-circle"
                      id="emailProfile"
                      onClick={() => {
                        window.open(
                          `mailto:${leadShow?.email || "#"}`,
                          "_blank"
                        );
                      }}
                    >
                      <EnvelopeIcon style={{ width: 20 }} />
                    </Button>
                  </OverlayTrigger>
                </>
              ) : (
                <>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Not available</Tooltip>}
                  >
                    <Button
                      variant="light"
                      className="rounded-circle"
                      id="emailProfile"
                      style={{ cursor: "not-allowed" }}
                    >
                      <EnvelopeIcon style={{ width: 20, fill: "gray" }} />
                    </Button>
                  </OverlayTrigger>
                </>
              )}

              <div>
                {!leadShow?.linkedin ||
                leadShow?.linkedin?.toLowerCase() === "na" ||
                leadShow?.linkedin?.toLowerCase() === "n/a" ? (
                  <>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Not available</Tooltip>}
                    >
                      <Button
                        variant="light"
                        className="rounded-circle"
                        id="linkedinProfile"
                        style={{ cursor: "not-allowed" }}
                      >
                        <LinkedinIcon style={{ width: 20, fill: "gray" }} />
                      </Button>
                    </OverlayTrigger>
                  </>
                ) : (
                  <>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>{leadShow.linkedin}</Tooltip>}
                    >
                      <Button
                        variant="light"
                        className="rounded-circle"
                        id="linkedinProfile"
                        onClick={() => {
                          window.open(checkHttp(leadShow.linkedin), "_blank");
                        }}
                      >
                        <LinkedinIcon style={{ width: 20, fill: "#0077b5" }} />
                        {/*<a
                          href={checkHttp(leadShow.linkedin)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          LinkedIn
                        </a>*/}
                      </Button>
                    </OverlayTrigger>
                  </>
                )}
              </div>

              <div>
                {!leadShow?.company?.phone ||
                leadShow?.company?.phone?.toLowerCase() === "na" ? (
                  <>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Not available</Tooltip>}
                    >
                      <Button
                        variant="light"
                        className="rounded-circle"
                        id="phoneProfile"
                        style={{ cursor: "not-allowed" }}
                      >
                        <FontAwesomeIcon
                          icon={faPhone}
                          size="sm"
                          color="gray"
                        />
                      </Button>
                    </OverlayTrigger>
                  </>
                ) : (
                  <>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>{leadShow?.company?.phone}</Tooltip>}
                    >
                      <Button
                        variant="light"
                        className="rounded-circle"
                        id="phoneProfile"
                        onClick={() => {
                          window.open(
                            `tel:${leadShow?.company?.phone}`,
                            "_blank"
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={faPhone} size="sm" />
                      </Button>
                    </OverlayTrigger>
                  </>
                )}
              </div>
            </div>
          )}

          {/*{!isOnPageLoading &&
            (leadShow?.status === "accepted" ||
            leadShow?.status === "acccepted" ? (
              <div />
            ) : (
              <p className="pt-2 font-weight-normal">
                <a
                  href="#"
                  data-toggle="modal"
                  data-target="#confirmaccept"
                  onClick={() => handleShow()}
                  className="btn btn-falcon-default d-block action-button text-white"
                >
                  Accept Lead to View Contact Information
                </a>
              </p>
            ))}*/}
          {isOnPageLoading && (
            <ContentLoader
              speed={2}
              width="100%"
              height={28}
              // viewBox="0 0 50 18"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="0" rx="3" ry="3" width="50%" height="34" />
            </ContentLoader>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileWithImage;
