import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const excerpt = (
  str: any,
  limit: number,
  show: number = 150,
  hide: number = 100
) => {
  let shortText = str;
  if (str.length > limit) {
    shortText = str.substring(0, limit) + "...";

    return (
      <OverlayTrigger
        // placement="top"
        delay={{ show: show, hide: hide }}
        overlay={<Tooltip>{str}</Tooltip>}
      >
        <abbr title="">{shortText}</abbr>
      </OverlayTrigger>
    );
  }

  return str;
};

export const analyticsAndCreatedDateLogic = (
  featureAnalyticsEnabled: boolean,
  createdDate: any
) => {
  return featureAnalyticsEnabled && createdDate.year() > 2022;
};
