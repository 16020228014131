export const devLog = (...data: any) => {
  if (process.env.NODE_ENV === "development") {
    console.log(data);
  }
};

export const devLogError = (...data: any) => {
  if (process.env.NODE_ENV === "development") {
    console.error(data);
  }
};

export const devLogGroup = (...data: any) => {
  if (process.env.NODE_ENV === "development") {
    console.group(data);
  }
};

export const devLogGroupEnd = () => {
  if (process.env.NODE_ENV === "development") {
    console.groupEnd();
  }
};

export const sliceString = (text: string, end: number = 1000) => {
  return text.slice(0, end);
};

export const emailAddressPattern = {
  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  message: "Invalid email address.",
};

export const checkHttp = (text: string) => {
  if (!text || text?.toLowerCase() === "na") return "";
  if (text?.startsWith("http")) {
    return text;
  } else {
    return `https://www.${text}`;
  }
};
