import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = (props: any) => {
  let history = useNavigate();

  useEffect(() => {
    // @ts-ignore
    _gs("unidentify");
    Object.keys(localStorage).forEach(function (key) {
      localStorage.removeItem(key);
    });
    history("/login");
    window.location.href = "/login";
  }, [history]);

  return <p>Logging out...</p>;
};

export default Logout;
