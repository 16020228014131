import { useState } from "react";

export const useAppHook = () => {
  let stepsValue: any = [
    {
      title: "Settings",
      content: <div>A new version of analytics page.</div>,
      // locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
      placement: "bottom",
      target: "#analytics-nav-menu",
      // disableBeacon: true,
      placementBeacon: "bottom-start",
      spotlightClicks: false,
      disableOverlay: false,
      disableOverlayClose: true,
      hideCloseButton: true,
      // hideFooter: true,
      // spotlightClicks: true,
      disableScrolling: true,
      styles: {
        options: {
          zIndex: 900,
        },
      },
    },
    {
      title: "List/Panel View",
      content: (
        <div>You can view leads by list or panel by clicking this icon.</div>
      ),
      disableOverlayClose: false,
      hideCloseButton: true,
      // floaterProps: {
      //   disableAnimation: true,
      // },
      spotlightPadding: 20,
      disableScrolling: true,
      target: ".Highly-Qualified-Leads-list-and-panel-view",
    },
  ];

  const [{ run, steps }, setTourState] = useState<any>({
    run: false,
    steps: stepsValue,
  });

  const messages = {
    last: "Close",
    next: window.location.pathname !== "/" ? "Close" : "Next",
  };

  return {
    stepsValue,
    run,
    steps,
    setTourState,
    messages,
  };
};
