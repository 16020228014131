import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Col, Modal, Row } from "react-bootstrap";
import ContentLoader from "react-content-loader";
import CountUp from "react-countup";
import moment from "moment";
import ActionZone from "./ActionZone";
import Messages from "./Messages";
import News from "./News";
import http from "../../../service/axios";
import Corner1 from "../../../assets/img/illustrations/corner-1.png";
import Corner2 from "../../../assets/img/illustrations/corner-2.png";
import Corner3 from "../../../assets/img/illustrations/corner-3.png";
import LeadNotes from "./LeadNotes";
import { useAppContext } from "../../../context/contextLib";
import { analyticsAndCreatedDateLogic } from "../../../helpers/common";
import CompanyInfo from "./CompanyInfo";
import CompanyDetails from "./CompanyDetails";
import SimilarCompanies from "./SimilarCompanies";
import ProfileWithImage from "./ProfileWithImage";
import { devLog, devLogError } from "../../../helpers/utils";
import SyncButton from "../../../components/Button/SyncButton";

interface RouteParams {
  id: string;
}

const LeadDetailPage = () => {
  const { userProfile }: any = useAppContext();
  // @ts-ignore
  const { id } = useParams<RouteParams>();
  const [leadShow, setLeadShow] = useState<any>({});
  const [leadEvents, setLeadEvents] = useState<any>([]);
  const [acceptedModalShow, setAcceptedModalShow] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOnPageLoading, setIsOnPageLoading] = useState<boolean>(true);

  useEffect(() => {
    let isMounted = true;

    getLead().then(
      (res: any) => {
        devLog("test getLead res", res);
        if (res.data) {
          setTimeout(() => {
            setIsOnPageLoading(false);
            setLeadShow(res.data);
          });
        }
      },
      (e: any) => {
        devLogError("test getLead e", e.response);
        if (e.response?.data?.detail === "Not found.") {
          setLeadShow(null);
        }
        setTimeout(() => setIsOnPageLoading(false));
      }
    );

    void getLeadEvents();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleClose = () => setAcceptedModalShow(false);

  // const handleShow = () => setAcceptedModalShow(true);

  const updateStatus = async () => {
    setIsLoading(true);
    let response: any;
    response = await http.put(`/api/leads/${id}/`, {
      status: "acccepted",
    });
    devLog("test updateStatus response", response);
    if (response.data) {
      setLeadShow((beforeVal: any) => {
        beforeVal.status = response.data.status;
        return beforeVal;
      });
      setTimeout(() => setIsLoading(false));
    }
  };

  const getLead = async () => {
    return await http.get(`/api/client_leads/${id}/`);
  };

  const getLeadEvents = async () => {
    try {
      let response = await http.get(`/api/lead_events?lead_id=${id}`);
      devLog("getLeadEvents", response);
      setLeadEvents(response.data.results);
    } catch (e) {
      devLogError(e);
    }
  };

  const renderAcceptedModal = () => {
    return (
      <Modal
        show={acceptedModalShow}
        backdrop="static"
        onHide={() => handleClose()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Acceptance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Accepting lead will allow you to access email and will remove from
          Union's outreach
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              void updateStatus();
              handleClose();
            }}
          >
            Accept
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const CompanyInfoData = ({ children }: any) => {
    return (
      <Fragment>
        {children}
        {isOnPageLoading && (
          <ContentLoader
            speed={2}
            width={50}
            height={28}
            viewBox="0 0 50 18"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="0" rx="3" ry="3" width="178" height="34" />
          </ContentLoader>
        )}
      </Fragment>
    );
  };

  if (leadShow === null) {
    return <div className="text-center">Lead details not found.</div>;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={12} className="d-flex justify-content-end">
          <SyncButton leadId={id} />
        </Col>
      </Row>

      <div className="row">
        <div className="col-sm-12 col-lg-4 mb-3">
          <ProfileWithImage
            isOnPageLoading={isOnPageLoading}
            leadShow={leadShow}
          />
        </div>

        <div
          className="col-lg-4 col-xxl-4 pl-xxl-2 mb-3"
          style={{ paddingRight: 10 }}
        >
          <div className="card mb-3 h-100">
            <div className="card-header">
              <h5 className="my-2">Engagement</h5>
            </div>
            <table id="engagement" className="table">
              <tbody>
                <tr>
                  <th scope="row">Emails Sent</th>
                  <td>
                    {!isOnPageLoading && (
                      <CountUp end={leadShow?.emails_sent || 0} />
                    )}
                    {isOnPageLoading && (
                      <ContentLoader
                        speed={2}
                        width={50}
                        height={28}
                        viewBox="0 0 50 18"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                      >
                        <rect
                          x="0"
                          y="0"
                          rx="3"
                          ry="3"
                          width="178"
                          height="34"
                        />
                      </ContentLoader>
                    )}
                    {/*{leadShow?.emails_sent || 0}{" "}*/}
                    {/*{{ leadEngagement.touches }}*/}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Emails Opened</th>
                  <td>
                    {!isOnPageLoading && (
                      <CountUp end={leadShow?.emails_viewed || 0} />
                    )}
                    {isOnPageLoading && (
                      <ContentLoader
                        speed={2}
                        width={50}
                        height={28}
                        viewBox="0 0 50 18"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                      >
                        <rect
                          x="0"
                          y="0"
                          rx="3"
                          ry="3"
                          width="178"
                          height="34"
                        />
                      </ContentLoader>
                    )}
                    {/*{leadShow?.emails_viewed || 0}{" "}*/}
                    {/*{{ leadEngagement.opens }}*/}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Emails Clicked</th>
                  <td>
                    {!isOnPageLoading && (
                      <CountUp end={leadShow?.emails_clicked || 0} />
                    )}
                    {isOnPageLoading && (
                      <ContentLoader
                        speed={2}
                        width={50}
                        height={28}
                        viewBox="0 0 50 18"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                      >
                        <rect
                          x="0"
                          y="0"
                          rx="3"
                          ry="3"
                          width="178"
                          height="34"
                        />
                      </ContentLoader>
                    )}
                    {/*{leadShow?.emails_clicked || 0}{" "}*/}
                    {/*{{ leadEngagement.click }}*/}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Website Visits</th>
                  <td>
                    {!isOnPageLoading &&
                      (userProfile &&
                      analyticsAndCreatedDateLogic(
                        userProfile?.feature_analytics_enabled,
                        moment.utc(leadShow?.created_date)
                      ) ? (
                        <CountUp end={leadShow?.tracker_visits || 0} />
                      ) : (
                        <CountUp end={leadShow?.visits || 0} />
                      ))}
                    {isOnPageLoading && (
                      <ContentLoader
                        speed={2}
                        width={50}
                        height={28}
                        viewBox="0 0 50 18"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                      >
                        <rect
                          x="0"
                          y="0"
                          rx="3"
                          ry="3"
                          width="178"
                          height="34"
                        />
                      </ContentLoader>
                    )}
                    {/*{leadShow?.visits || 0}*/}{" "}
                    {/*{{ leadEngagement.visits }}*/}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Website Page Views</th>
                  <td>
                    {!isOnPageLoading &&
                      (userProfile &&
                      analyticsAndCreatedDateLogic(
                        userProfile?.feature_analytics_enabled,
                        moment.utc(leadShow?.created_date)
                      ) ? (
                        <CountUp end={leadShow?.tracker_pageviews || 0} />
                      ) : (
                        <CountUp end={leadShow?.pageviews || 0} />
                      ))}
                    {isOnPageLoading && (
                      <ContentLoader
                        speed={2}
                        width={50}
                        height={28}
                        viewBox="0 0 50 18"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                      >
                        <rect
                          x="0"
                          y="0"
                          rx="3"
                          ry="3"
                          width="178"
                          height="34"
                        />
                      </ContentLoader>
                    )}
                    {/*{leadShow?.pageviews || 0}{" "}*/}
                    {/*{{ leadEngagement.pageviews }}*/}
                  </td>
                </tr>
              </tbody>
            </table>
            {/*<div>
		          <form method="POST" id="post-form"></form>
		            <button id="refresh_engage" style="width: 50%;" data-engagementid="{{ leadEngagement.id }}" className="btn btn-falcon-default d-block">
		              Update Data
		            </button>
		          </form>
		        </div>*/}
          </div>
        </div>

        <ActionZone
          id={id}
          leadShow={leadShow}
          setLeadShow={setLeadShow}
          setIsLoading={setIsLoading}
          isOnPageLoading={isOnPageLoading}
        />
      </div>

      <div className="mb-5 pb-5">
        <div className="card-deck">
          <div
            className="card mb-3 overflow-hidden"
            style={{ minWidth: "12rem" }}
          >
            <div
              className="bg-holder bg-card"
              style={{
                backgroundImage: "url(" + Corner1 + ")",
              }}
            />
            <div className="card-body position-relative">
              <h5>
                Company Revenue
                <span className="badge badge-soft-info rounded-capsule ml-2" />
              </h5>
              <div className="display-4 fs-4 my-3 font-weight-normal color-dark-blue">
                {(!isOnPageLoading && leadShow?.company?.revenue_band) || (
                  <span className="text-muted">{"-"}</span>
                )}{" "}
                {isOnPageLoading && (
                  <ContentLoader
                    speed={2}
                    width={200}
                    height={28}
                    viewBox="0 0 200 18"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                  >
                    <rect
                      x="0"
                      y="0"
                      rx="3"
                      ry="3"
                      width="100%"
                      height="100%"
                    />
                  </ContentLoader>
                )}
                {/*{{ leadShow.company.revenueBand}}*/}
              </div>
            </div>
          </div>

          <div
            className="card mb-3 overflow-hidden"
            style={{ minWidth: "12rem" }}
          >
            <div
              className="bg-holder bg-card"
              style={{
                backgroundImage: "url(" + Corner2 + ")",
              }}
            />
            <div className="card-body position-relative">
              <h5>
                Emails Sent
                <span className="badge badge-soft-info rounded-capsule ml-2" />
              </h5>
              <div className="display-4 fs-4 my-3 font-weight-normal color-dark-blue">
                {!isOnPageLoading && (
                  <CountUp end={leadShow?.emails_sent || 0} />
                )}
                {isOnPageLoading && (
                  <ContentLoader
                    speed={2}
                    width={200}
                    height={28}
                    viewBox="0 0 200 18"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                  >
                    <rect
                      x="0"
                      y="0"
                      rx="3"
                      ry="3"
                      width="100%"
                      height="100%"
                    />
                  </ContentLoader>
                )}
                {/*{leadShow?.emails_sent || 0}*/}{" "}
                {/*{{ leadEngagement.touches }}*/}
              </div>
            </div>
          </div>

          <div
            className="card mb-3 overflow-hidden"
            style={{ minWidth: "12rem" }}
          >
            <div
              className="bg-holder bg-card"
              style={{
                backgroundImage: "url(" + Corner3 + ")",
              }}
            />
            <div className="card-body position-relative">
              <h5>
                {/*Alexa Rank*/}
                Company Industry
                <span className="badge badge-soft-info rounded-capsule ml-2" />
              </h5>
              <div
                className="display-4 fs-2 my-3 font-weight-normal color-dark-blue"
                // data-countup='{"count":{{leadShow.company.alexaRank}},"format":"comma","prefix":""}'
              >
                {/*{leadShow?.company?.alexa_rank || 0}*/} {/*0*/}
                {!isOnPageLoading && (
                  <CompanyInfoData>
                    {!isOnPageLoading &&
                      (leadShow?.company?.company_industry || (
                        <span className="fs-4 text-muted">{"-"}</span>
                      ))}
                  </CompanyInfoData>
                )}
                {/*{!isOnPageLoading && (
                  <CountUp
                    className="account-balance"
                    start={0}
                    end={leadShow?.company?.alexa_rank || 0}
                    duration={2.75}
                    separator=","
                    decimal=","
                  >
                    {({ countUpRef, start }) => (
                      <div>
                        <span ref={countUpRef} />
                      </div>
                    )}
                  </CountUp>
                )}*/}
                {isOnPageLoading && (
                  <ContentLoader
                    speed={2}
                    width={200}
                    height={28}
                    viewBox="0 0 200 18"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                  >
                    <rect
                      x="0"
                      y="0"
                      rx="3"
                      ry="3"
                      width="100%"
                      height="100%"
                    />
                  </ContentLoader>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-8">
            <LeadNotes lead={leadShow} />
            {/*<div id="attachments">
              {%if notes%}
			        {% include 'partials/_leadnotes.html' %}
			        {%else%}
			        {%endif%}
            </div>*/}
            {/*<div id="attachments">
			        {%if leadShow.hasAttachments%}
			        {% include 'partials/_attachments.html' %}
			        {%else%}
			        {%endif%}
			        </div>*/}

            <Messages
              id={id}
              leadShow={leadShow}
              setLeadShow={setLeadShow}
              setIsLoading={setIsLoading}
            />

            {userProfile &&
              analyticsAndCreatedDateLogic(
                userProfile?.feature_analytics_enabled,
                moment.utc(leadShow?.created_date)
              ) && (
                <div className="card mb-3">
                  <div className="card-header">
                    <h5 className="my-2">Page Views</h5>
                  </div>
                  <table className="table">
                    <tbody>
                      {leadEvents?.length > 0 ? (
                        leadEvents.map((event: any) => {
                          return (
                            <tr>
                              <th>
                                {event?.page && (
                                  <a
                                    href={event.page}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {event.page}
                                  </a>
                                )}
                              </th>
                              <td>{event.count}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <th className="text-muted">No data yet.</th>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}

            {userProfile && userProfile?.company_news && (
              <div id="news">
                <News />
              </div>
            )}

            {/*<div>
			        {%if user.client.websiteTracking%}
			        {% if visits %}
			        {% include 'partials/_website_activity.html'%}
			        {%else%}
			        {%endif%}
			        {% else %}
			        {%endif%}
			      </div>*/}
          </div>

          <div className="col-lg-4">
            <div className="sticky-top sticky-sidebar">
              <CompanyInfo
                isOnPageLoading={isOnPageLoading}
                leadShow={leadShow}
              />

              <CompanyDetails
                isOnPageLoading={isOnPageLoading}
                leadShow={leadShow}
              />

              <SimilarCompanies
                isOnPageLoading={isOnPageLoading}
                leadShow={leadShow}
              />
            </div>
          </div>
        </div>

        {/*{%else%}
			  <h1>Please login before viewing your leads... </h1>
			  {%endif%}*/}
      </div>

      {renderAcceptedModal()}
    </Fragment>
  );
};

export default LeadDetailPage;
