import React from "react";
import CompanyInfoData from "./CompanyInfoDataLoader";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faPhone } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as LinkedinIcon } from "../../../assets/svg/linkedin.svg";
import { checkHttp } from "../../../helpers/utils";

type CompanyInfoProps = {
  isOnPageLoading: boolean;
  leadShow: any;
};

const CompanyInfo = ({ isOnPageLoading, leadShow }: CompanyInfoProps) => {
  return (
    <div className="card mb-3">
      <div className="card-header">
        <h5 className="my-2">Company Info</h5>
      </div>

      <table style={{ wordBreak: "break-word" }} id="table" className="table">
        <tbody>
          <tr>
            <td colSpan={2}>
              <div className="d-flex flex-row justify-content-evenly align-items-center">
                {leadShow?.company?.url ? (
                  <>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>{leadShow?.company?.url}</Tooltip>}
                    >
                      <Button
                        variant="light"
                        className="rounded-circle"
                        id="website"
                        onClick={() => {
                          window.open(leadShow?.company?.url, "_blank");
                        }}
                      >
                        <FontAwesomeIcon icon={faLink} size="sm" />
                      </Button>
                    </OverlayTrigger>
                  </>
                ) : (
                  <>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>No website</Tooltip>}
                    >
                      <Button
                        variant="light"
                        className="rounded-circle"
                        id="website"
                        style={{ cursor: "not-allowed" }}
                      >
                        <FontAwesomeIcon icon={faLink} size="sm" color="gray" />
                      </Button>
                    </OverlayTrigger>
                  </>
                )}

                {!leadShow?.company?.company_linkedin ||
                leadShow?.company?.company_linkedin?.toLowerCase() === "na" ||
                leadShow?.company?.company_linkedin?.toLowerCase() === "n/a" ? (
                  <>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>No linkedin</Tooltip>}
                    >
                      <Button
                        variant="light"
                        className="rounded-circle"
                        id="companyLinkedin"
                        style={{ cursor: "not-allowed" }}
                      >
                        <LinkedinIcon style={{ width: 20, fill: "gray" }} />
                      </Button>
                    </OverlayTrigger>
                  </>
                ) : (
                  <>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip>{leadShow?.company?.company_linkedin}</Tooltip>
                      }
                    >
                      <Button
                        variant="light"
                        className="rounded-circle"
                        id="companyLinkedin"
                        onClick={() => {
                          window.open(
                            checkHttp(leadShow?.company?.company_linkedin),
                            "_blank"
                          );
                        }}
                      >
                        <LinkedinIcon style={{ width: 20, fill: "#0077b5" }} />
                      </Button>
                    </OverlayTrigger>
                  </>
                )}

                {!leadShow?.company?.phone ||
                leadShow?.company?.phone?.toLowerCase() === "na" ? (
                  <>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Not available</Tooltip>}
                    >
                      <Button
                        variant="light"
                        className="rounded-circle"
                        id="companyPhone"
                        style={{ cursor: "not-allowed" }}
                      >
                        <FontAwesomeIcon
                          icon={faPhone}
                          size="sm"
                          color="gray"
                        />
                      </Button>
                    </OverlayTrigger>
                  </>
                ) : (
                  <>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>{leadShow?.company?.phone}</Tooltip>}
                    >
                      <Button
                        variant="light"
                        className="rounded-circle"
                        id="companyPhone"
                        onClick={() => {
                          window.open(
                            `tel:${leadShow?.company?.phone}`,
                            "_blank"
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={faPhone} size="sm" />
                      </Button>
                    </OverlayTrigger>
                  </>
                )}
              </div>
            </td>
          </tr>
          {/*<tr>
            <th scope="row">Company LinkedIn</th>
            <td>
              <CompanyInfoData isOnPageLoading={isOnPageLoading}>
                {!isOnPageLoading && (
                  <a
                    href={`https://www.${leadShow?.company?.company_linkedin}`}
                  >
                    {leadShow?.company?.company_linkedin}
                  </a>
                )}
              </CompanyInfoData>
            </td>
          </tr>*/}
          {/*<tr>
            <th scope="row">Website</th>
            <td>
              <CompanyInfoData isOnPageLoading={isOnPageLoading}>
                {!isOnPageLoading && (
                  <a href={`https://www.${leadShow?.company?.url}`}>
                    {leadShow?.company?.url}
                  </a>
                )}
              </CompanyInfoData>
            </td>
          </tr>*/}
          {/*<tr>
            <th scope="row">Phone</th>
            <td>
              <CompanyInfoData isOnPageLoading={isOnPageLoading}>
                {!isOnPageLoading &&
                  (leadShow?.company?.phone || "not available")}
              </CompanyInfoData>
            </td>
          </tr>*/}
          <tr>
            <th scope="row">City</th>
            <td>
              <CompanyInfoData isOnPageLoading={isOnPageLoading}>
                {!isOnPageLoading && (leadShow?.company?.city || "-")}
              </CompanyInfoData>
            </td>
          </tr>
          <tr>
            <th scope="row">State</th>
            <td>
              <CompanyInfoData isOnPageLoading={isOnPageLoading}>
                {!isOnPageLoading && (leadShow?.company?.state || "-")}
              </CompanyInfoData>
            </td>
          </tr>
          <tr>
            <th scope="row"> Zip Code</th>
            <td>
              <CompanyInfoData isOnPageLoading={isOnPageLoading}>
                {!isOnPageLoading && (leadShow?.company?.zip || "-")}
              </CompanyInfoData>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default CompanyInfo;
