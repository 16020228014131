import { Spinner } from "react-bootstrap";

interface SuspenseFallbackProps {}

export const SuspenseFallback: React.FunctionComponent<
  SuspenseFallbackProps
> = () => {
  return (
    <div className="suspense-fallback">
      <Spinner animation="border" />
    </div>
  );
};
