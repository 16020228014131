/**
 * Loads something from storage and runs it thru JSON.parse.
 *
 * @param key The key to fetch.
 */
export async function load(key: string): Promise<any | null> {
  try {
    const almostThere: any = await localStorage.getItem(key);
    return JSON.parse(almostThere);
  } catch {
    return null;
  }
}
