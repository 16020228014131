import { FC, lazy, Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

// components
import SuspenseSpinner from "components/SuspenseFallback";
import LeadDetailPage from "../pages/lead/lead-detail/LeadDetailPage";
import Login from "../pages/Auth/login/Login";
import Logout from "../pages/Auth/logout/Logout";
import { devLog } from "../helpers/utils";
const PasswordReset = lazy(
  () => import("../pages/Auth/password-reset/PasswordReset")
);
const PasswordResetKey = lazy(
  () => import("../pages/Auth/password-reset/PasswordResetKey")
);

// pages
const DashboardPage = lazy(() => import("../pages/dashboard/DashboardPage"));
const AnalyticsPage = lazy(() => import("../pages/analytics/AnalyticsPage"));
const SettingsPage = lazy(() => import("../pages/settings/SettingsPage"));
const NoMatchPage = lazy(() => import("../pages/nomatch/NoMatchPage"));
const V2AnalyticsPage = lazy(() => import("../pages/v2/analytics/Analytics"));
const OnboardingNew = lazy(() => import("../pages/Onboarding/OnboardingNew"));
const AcceptInvite = lazy(() => import("../pages/accept-invite/AcceptInvite"));

const RenderRoutes: FC = ({ children, ...props }) => {
  useEffect(() => {
    devLog(
      "Rendering routes...",
      window.location.pathname + window.location.search
    );
    // @ts-ignore
    _gs("track", window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      {children}
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<SuspenseSpinner />}>
              <DashboardPage />
            </Suspense>
          }
        />

        <Route
          path="login"
          element={
            <Suspense fallback={<SuspenseSpinner />}>
              <Login />
            </Suspense>
          }
        />
        <Route path="logout" element={<Logout />} />
        <Route
          path="password/reset"
          element={
            <Suspense fallback={<SuspenseSpinner />}>
              <PasswordReset />
            </Suspense>
          }
        />
        <Route
          path="password/reset/:key"
          element={
            <Suspense fallback={<SuspenseSpinner />}>
              <PasswordResetKey />
            </Suspense>
          }
        />

        <Route
          path="analytics"
          element={
            <Suspense fallback={<SuspenseSpinner />}>
              <V2AnalyticsPage />
            </Suspense>
          }
        />
        <Route
          path="v2/analytics"
          element={
            <Suspense fallback={<SuspenseSpinner />}>
              <AnalyticsPage />
            </Suspense>
          }
        />
        {/*<Route
          path="onboarding"
          element={
            <Suspense fallback={<SuspenseSpinner />}>
              <Onboarding />
            </Suspense>
          }
        />*/}
        <Route
          path="onboarding"
          element={
            <Suspense fallback={<SuspenseSpinner />}>
              <div className="OnboardingPage">
                <OnboardingNew />
              </div>
            </Suspense>
          }
        />
        <Route
          path="accept-invite/:key"
          element={
            <Suspense fallback={<SuspenseSpinner />}>
              <AcceptInvite />
            </Suspense>
          }
        />
        <Route
          path="settings"
          element={
            <Suspense fallback={<SuspenseSpinner />}>
              <SettingsPage />
            </Suspense>
          }
        />
        <Route path="/:id" element={<LeadDetailPage />} />
        <Route path="*" element={<NoMatchPage />} />
      </Routes>
    </>
  );
};

export default RenderRoutes;
