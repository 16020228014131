import React, { Fragment } from "react";
import DefaultIcon from "../../../assets/img/logo/logo-only.jpeg";
import _ from "lodash";
import ContentLoader from "react-content-loader";

type SimilarCompaniesProps = {
  isOnPageLoading: boolean;
  leadShow: any;
};

const SimilarCompanies = ({
  isOnPageLoading,
  leadShow,
}: SimilarCompaniesProps) => {
  return (
    <div className="card mb-3">
      <div className="card-header">
        <h5 className="my-2">Similar Companies</h5>
      </div>
      <div
        style={{
          float: "left",
          clear: "none",
          margin: 10,
          textAlign: "center",
        }}
      >
        {(!leadShow?.company?.similar_company_domain_1 ||
          !leadShow?.company?.similar_company_domain_2 ||
          !leadShow?.company?.similar_company_domain_3) && (
          <div className="text-muted">No data yet.</div>
        )}

        {!isOnPageLoading && (
          <Fragment>
            {leadShow?.company?.similar_company_domain_1 &&
              leadShow?.company?.similar_company_domain_1?.toLowerCase() !==
                "na" && (
                <div className="avatar avatar-4xl">
                  <a
                    href={`http://www.${leadShow.company.similar_company_domain_1}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="rounded-circle"
                      src={`https://logo.clearbit.com/${leadShow.company.similar_company_domain_1}?size=100`}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = DefaultIcon;
                      }}
                      alt="company"
                      decoding="async"
                    />
                  </a>
                </div>
              )}

            {leadShow?.company?.similar_company_domain_2 &&
              leadShow?.company?.similar_company_domain_2?.toLowerCase() !==
                "na" && (
                <div className="avatar avatar-4xl">
                  <a
                    href={`http://www.${leadShow.company.similar_company_domain_2}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="rounded-circle"
                      src={`https://logo.clearbit.com/${leadShow.company.similar_company_domain_2}?size=100`}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = DefaultIcon;
                      }}
                      alt="company"
                      decoding="async"
                    />
                  </a>
                </div>
              )}

            {leadShow?.company?.similar_company_domain_3 &&
              leadShow?.company?.similar_company_domain_3?.toLowerCase() !==
                "na" && (
                <div className="avatar avatar-4xl">
                  <a
                    href={`http://www.${leadShow.company.similar_company_domain_3}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="rounded-circle"
                      src={`https://logo.clearbit.com/${leadShow.company.similar_company_domain_3}?size=100`}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = DefaultIcon;
                      }}
                      alt="company"
                      decoding="async"
                    />
                  </a>
                </div>
              )}
          </Fragment>
        )}

        {isOnPageLoading &&
          _.map([...Array(3)], (key: any, index: number) => {
            return (
              <div className="avatar avatar-4xl" key={index}>
                <ContentLoader viewBox="0 0 400 160" height={160} width={400}>
                  <circle cx="48" cy="48" r="48" />
                </ContentLoader>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default SimilarCompanies;
