import http from "./axios";
import { endpoints } from "./endpoints";

export const deleteMessageService = async (id: number) => {
  try {
    return await http.delete(`${endpoints.CRM_ACTIVITIES}${id}`);
  } catch (e: any) {
    return e.response;
  }
};

export const hideMessageService = async (id: number) => {
  try {
    return await http.post(`${endpoints.CRM_ACTIVITIES}${id}/hide/`);
  } catch (e: any) {
    return e.response;
  }
};
