export const endpoints = {
  //SSO
  LOGIN: "/sso/verifyuser",
  UPDATE_PROFILE: "/sso/userprofile",
  CRM_ACTIVITIES: "/api/crm_activities/",
};

let API_BOOK = {
  LOGIN_API: {
    url: endpoints.LOGIN,
    method: "POST",
    data: null,
  },
};

export default API_BOOK;
