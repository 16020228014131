import { useCallback, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ContentLoader from "react-content-loader";

// service
import http from "../../../service/axios";
import { devLog } from "../../../helpers/utils";

interface ActionZoneInterface {
  id: any;
  leadShow: any;
  setLeadShow: any;
  setIsLoading: any;
  isOnPageLoading: boolean;
}

const ActionZone = ({
  id,
  leadShow,
  setLeadShow,
  setIsLoading,
  isOnPageLoading = false,
}: ActionZoneInterface) => {
  const [acceptedLeadShow, setAcceptedLeadShow] = useState<boolean>(false);
  const [favoriteLeadShow, setFavoriteLeadShow] = useState<boolean>(false);
  const [archiveLeadShow, setArchiveLeadShow] = useState<boolean>(false);

  const handleClose = (name: string = "accepted") => {
    switch (name) {
      case "accepted":
        setAcceptedLeadShow(false);
        break;
      case "favorite":
        setFavoriteLeadShow(false);
        break;
      case "archived":
        setArchiveLeadShow(false);
    }
  };

  const handleShow = (name: string = "accepted") => {
    switch (name) {
      case "accepted":
        setAcceptedLeadShow(true);
        break;
      case "favorite":
        setFavoriteLeadShow(true);
        break;
      case "archived":
        setArchiveLeadShow(true);
    }
  };

  const updateStatus = async (name: string = "acccepted") => {
    setIsLoading(true);
    let response: any;
    if (name === "favorite") {
      response = await http.put(`/api/client_leads/${id}/`, {
        is_favorite: true,
      });
    } else {
      response = await http.put(`/api/client_leads/${id}/`, {
        status: name,
      });
    }
    devLog("test updateStatus response", response);
    if (response.data) {
      setLeadShow((beforeVal: any) => {
        if (name === "favorite") {
          beforeVal.is_favorite = true;
          beforeVal.status = response.data.status;
        } else {
          beforeVal.status = response.data.status;
        }
        return beforeVal;
      });
      setTimeout(() => setIsLoading(false));
    }
  };

  const renderAcceptedModal = () => {
    return (
      <Modal
        show={acceptedLeadShow}
        backdrop="static"
        onHide={() => handleClose("accepted")}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Acceptance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Accepting lead will allow you to access email and will remove from
          Union's outreach
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose("accepted")}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              // @ts-ignore
              _gs("event", "Accept Lead", {
                lead_id: id,
              });
              void updateStatus();
              handleClose("accepted");
            }}
          >
            Accept
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderFavoriteModal = () => {
    return (
      <Modal
        show={favoriteLeadShow}
        backdrop="static"
        onHide={() => handleClose("favorite")}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Favorite</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose("favorite")}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              // @ts-ignore
              _gs("event", "Favorite Lead", {
                lead_id: id,
              });
              void updateStatus("favorite");
              handleClose("favorite");
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderArchivedModal = () => {
    return (
      <Modal
        show={archiveLeadShow}
        backdrop="static"
        onHide={() => handleClose("archived")}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Archive</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This will hide the lead and will no longer update real time data. You
          can retrieve through your dashboard.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose("archived")}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              // @ts-ignore
              _gs("event", "Archive Lead", {
                lead_id: id,
              });
              void updateStatus("archived");
              handleClose("archived");
            }}
          >
            Got it
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const actionZoneAcceptedLead = useCallback(() => {
    if (leadShow?.status === "accepted" || leadShow?.status === "acccepted") {
      return (
        <>
          <h6 className="mb-0" style={{ marginTop: "2%" }}>
            Accept Lead
          </h6>
          <small className="text-muted" style={{ marginBottom: "3%" }}>
            This is already an accepted lead!
          </small>
        </>
      );
    } else {
      return (
        <>
          <h6 className="mb-0" style={{ marginTop: "2%" }}>
            Accept Lead
          </h6>
          <small className="text-muted" style={{ marginBottom: "3%" }}>
            Choose if you want to reach out to this lead now!
          </small>
          <a
            className="btn btn-falcon-default d-block action-button"
            data-toggle="modal"
            data-target="#confirmaccept"
            data-leadcompleteid={leadShow?.id || 0}
            onClick={() => handleShow("accepted")}
            style={{ marginTop: 5 }}
          >
            Accept
          </a>
        </>
      );
    }
  }, [leadShow]);

  const actionZoneFavoriteLead = useCallback(() => {
    if (!leadShow?.is_favorite && leadShow?.status === "open") {
      return (
        <>
          <h6 className="mb-0" style={{ marginTop: "3%" }}>
            Favorite Lead
          </h6>
          <small className="text-muted" style={{ marginBottom: "3%" }}>
            Add this lead to your watch list.
          </small>
          <a
            className="btn btn-falcon-default d-block action-button"
            id="makeFavorite"
            data-leadcompleteid={leadShow?.id}
            onClick={() => {
              handleShow("favorite");
            }}
            style={{ marginTop: 5 }}
          >
            Favorite
          </a>
        </>
      );
    } else {
      return (
        <>
          <h6 id="favoriteAction" className="mb-0" style={{ marginTop: "3%" }}>
            Favorite Lead
          </h6>
          <small className="text-muted" style={{ marginBottom: "3%" }}>
            You have favorited this lead.
          </small>
        </>
      );
    }
  }, [leadShow]);

  return (
    <div className="col-lg-4 col-xxl-4 pl-xxl-2 mb-3">
      <div className="card mb-3 h-100">
        <div className="card-header">
          <h5 className="my-2">Action Zone</h5>
        </div>
        {!isOnPageLoading && (
          <div className="card-body" id="actionzone">
            {actionZoneAcceptedLead()}

            <hr className="border border-dashed my-4 w-50 mx-auto" />

            {actionZoneFavoriteLead()}

            <hr className="border border-dashed my-4 w-50 mx-auto" />

            <h6 className="mb-0" style={{ marginTop: "3%" }}>
              Archive Lead
            </h6>
            <small className="text-muted">
              {leadShow?.status === "archived"
                ? "This lead has been archived."
                : "Hide lead from your current views."}
            </small>
            {leadShow?.status !== "archived" ? (
              <a
                className="btn btn-secondary d-block"
                data-toggle="modal"
                data-target="#confirmarchive"
                data-leadcompleteid={leadShow?.id}
                onClick={() => handleShow("archived")}
                style={{ marginTop: 5 }}
              >
                Archive
              </a>
            ) : (
              <></>
            )}
          </div>
        )}
        {isOnPageLoading && (
          <div className="card-body" id="actionzone">
            <ContentLoader height="100%" width="100%" viewBox="0 0 265 280">
              <rect x="0" y="0" rx="2" ry="2" width="100%" height="100%" />
            </ContentLoader>
          </div>
        )}

        {renderAcceptedModal()}
        {renderFavoriteModal()}
        {renderArchivedModal()}
      </div>
    </div>
  );
};

export default ActionZone;
