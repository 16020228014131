import { useState } from "react";
import { hideMessageService } from "../../service/crm-activities.service";
import { devLog, devLogError } from "../../helpers/utils";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

type HideMessageTypes = {
  id: number;
  onLoadApi: any;
};

const HideMessage = ({ id, onLoadApi }: HideMessageTypes) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const handleHideEmail = async () => {
    try {
      let response = await hideMessageService(id);
      if (response?.data) {
        devLog("handleHideEmail() response", response);
        handleClose();
        toast.success(`Message successfully deleted!`);
        onLoadApi();
      }
    } catch ({ response }) {
      devLogError(response);
      handleClose();
      toast.error("There's something wrong.");
    }
  };

  return (
    <>
      <button
        className="btn btn-falcon-default mt-sm-3 btn-sm ml-2"
        type="button"
        onClick={handleOpen}
        style={{ fontSize: "0.7rem" }}
      >
        Hide
      </button>

      {open && (
        <Modal show={open} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Hide</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure?</Modal.Body>
          <Modal.Footer>
            <Button
              className="btn btn-falcon-default btn-sm text-white"
              type="button"
              onClick={handleHideEmail}
            >
              Yes, hide it!
            </Button>
            <Button
              variant="secondary"
              onClick={handleClose}
              className="btn-sm"
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default HideMessage;
