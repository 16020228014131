import { useEffect, useLayoutEffect, useState } from "react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faReply } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Col,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import moment from "moment";
import ContentLoader from "react-content-loader";

// components
import { RcPagination } from "../../../components/Pagination/RcPagination";

// utils
import { devLog, devLogError, sliceString } from "../../../helpers/utils";

// service
import http from "../../../service/axios";

import PentagonRightIcon from "../../../assets/svg/pentagon-right.svg";
import {
  deleteMessageService,
  hideMessageService,
} from "../../../service/crm-activities.service";
import { load } from "../../../helpers/storage";
import DeleteMessage from "../../../components/Button/DeleteMessage";
import HideMessage from "../../../components/Button/HideMessage";

// vendor
const MailtoUI = require("mailtoui/dist/mailtoui-min.js");

interface MessagesInterface {
  id: any;
  leadShow: any;
  setLeadShow: any;
  setIsLoading: any;
}

const Messages = ({
  id,
  leadShow,
  setLeadShow,
  setIsLoading,
}: MessagesInterface) => {
  const [profile, setProfile] = useState<any>(null);
  const [leadMessages, setLeadMessages] = useState<any[]>([]);
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [total, setTotal] = useState<any>(0);
  const [filters, setFilters] = useState<any>({
    limit: 5,
    offset: 0,
    currentPage: 1,
  });

  const [isOnPageLoading, setIsOnPageLoading] = useState<boolean>(true);

  useEffect(() => {
    let isMounted = true;
    setIsOnPageLoading(true);

    GetProfile();

    void getMessages();

    if (isMounted) {
      setTimeout(() => MailtoUI.run(), 500);
    }

    return () => {
      isMounted = false;
    };
  }, [filters]);

  useLayoutEffect(() => {
    setTimeout(() => MailtoUI.run(), 500);
  }, []);

  const getMessages = async () => {
    try {
      let response = await http.get(
        `/api/client_leads/${id}/messages/?limit=5&offset=${filters.offset}`
      );
      if (response?.data) {
        setLeadMessages(response.data.results);
        setTotal(response.data.count);
        setTimeout(() => {
          setIsOnPageLoading(false);
          setTimeout(() => MailtoUI.run(), 500);
        });
      }
    } catch (e: any) {
      devLogError("test e.response", e?.response);
      setTimeout(() => setIsOnPageLoading(false));
    }
  };

  const GetProfile = async () => {
    try {
      let response = await load("profile");
      if (response) {
        setProfile(response);
      }
    } catch (e: any) {
      devLogError(e, e?.response);
    }
  };

  const handleClose = () => {
    setModalShow(false);
  };

  const handleShow = () => {
    setModalShow(true);
  };

  const handleCheckMessages = async () => {
    setIsOnPageLoading(true);
    // @ts-ignore
    _gs("event", "Check Messages", {
      lead_id: id,
    });
    try {
      let response = await http.post(
        `/api/client_leads/${id}/messages/?limit=5&offset=${filters.offset}`,
        {}
      );
      // devLog("test response", response);
      setFilters({
        limit: 5,
        offset: 0,
        currentPage: 1,
      });
      if (response.data) {
        setLeadMessages(response.data.results);
        setTotal(response.data.count);
        setTimeout(() => MailtoUI.run(), 500);
      }
    } catch (e: any) {
      devLogError("test e.response", e.response);
      setTimeout(() => setIsOnPageLoading(false));
    }
  };

  const handleViewHideMsg = (e: any) => {
    if (e.target.innerHTML === "View Message") {
      e.target.innerHTML = "Hide Message";
    } else {
      e.target.innerHTML = "View Message";
    }
    setTimeout(() => MailtoUI.run(), 500);
  };

  const handleDeleteEmail = async (id: number) => {
    try {
      let response = await deleteMessageService(id);
      if (response?.data) {
        devLog("handleDeleteEmail() response", response);
      }
    } catch ({ response }) {
      devLogError(response);
    }
  };

  const handleHideEmail = async (id: number) => {
    try {
      let response = await hideMessageService(id);
      if (response?.data) {
        devLog("handleHideEmail() response", response);
      }
    } catch ({ response }) {
      devLogError(response);
    }
  };

  const updateStatus = async () => {
    setIsLoading(true);
    let response = await http.put(`/api/leads/${id}/`, {
      status: "acccepted",
    });
    devLog("test updateStatus response", response);
    if (response.data) {
      setLeadShow((beforeVal: any) => {
        beforeVal.status = response.data.status;
        return beforeVal;
      });
      setTimeout(() => setIsLoading(false));
    }
  };

  const renderAcceptedModal = () => {
    return (
      <Modal show={modalShow} backdrop="static" onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Acceptance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Accepting lead will allow you to access email and will remove from
          Union's outreach
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              void updateStatus();
              handleClose();
            }}
          >
            Accept
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ReplyIcon = ({ subject }: any) => {
    if (subject && subject.toLowerCase().includes("re:")) {
      return (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="mail-reply">Reply</Tooltip>}
        >
          <div>
            <FontAwesomeIcon
              icon={faReply}
              aria-hidden="true"
              size="1x"
              style={{ marginRight: 6 }}
            />
          </div>
        </OverlayTrigger>
      );
    }

    return <></>;
  };

  const ReplyIconFromProspect = ({ subject }: any) => {
    if (
      subject &&
      (subject.toLowerCase().includes("reply: re:") ||
        subject.toLowerCase().includes("reply: "))
    ) {
      return (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="mail-reply-from-prospect">Reply from prospect</Tooltip>
          }
        >
          <div>
            {/*<FontAwesomeIcon
              icon={faStar}
              aria-hidden="true"
              size="1x"
              color="#dc582e"
              style={{ marginRight: 3 }}
            />*/}
            <img
              src={PentagonRightIcon}
              alt="prospects-reply"
              style={{ marginTop: -5, marginLeft: -6 }}
            />
          </div>
        </OverlayTrigger>
      );
    }

    return <></>;
  };

  return (
    <div className="card md-10 mb-3">
      <div className="card-header container-fluid">
        <div className="row">
          <div className="col-lg-3">
            <h5 className="my-2">Messages</h5>
          </div>
          <div className="col-lg-9">
            <button
              id="checkMessages"
              data-lead={leadShow?.id || 0}
              className="float-right btn btn-falcon-default float-right d-block"
              onClick={handleCheckMessages}
              aria-disabled={isOnPageLoading}
              disabled={isOnPageLoading}
            >
              {isOnPageLoading && <Spinner size="sm" animation="border" />}{" "}
              Check Messages
            </button>
          </div>
        </div>
      </div>

      <div id="messages" className="card-body text-justify">
        <div className="collapse show" id="profile-intro">
          {!isOnPageLoading &&
            (leadMessages?.length > 0 ? (
              _.map(leadMessages, (leadMessage: any) => {
                return (
                  <div key={leadMessage?.id || 0}>
                    <div className="card card shadow-none p-1 p-sm-4 mb-3">
                      <div className="p-2">
                        <div className="d-flex flex-column flex-md-row justify-content-md-between">
                          <div className="d-flex">
                            <FontAwesomeIcon
                              icon={faEnvelope}
                              aria-hidden="true"
                              size="1x"
                              style={{ marginTop: 3, marginRight: 6 }}
                            />

                            <ReplyIcon subject={leadMessage?.subject} />

                            <ReplyIconFromProspect
                              subject={leadMessage?.subject}
                            />

                            <span
                              className={`mb-0 text-start text-wrap text-break ${
                                leadMessage?.subject &&
                                leadMessage?.subject
                                  .toLowerCase()
                                  .includes("reply: re:")
                                  ? "text-primary"
                                  : ""
                              }`}
                              style={{ fontSize: "0.8rem", fontWeight: 600 }}
                            >
                              {leadMessage?.subject}
                            </span>
                          </div>

                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="mail-date">
                                {moment(leadMessage.updated_at).format("LLLL")}
                              </Tooltip>
                            }
                          >
                            <div
                              className="text-end font-italic"
                              style={{ fontSize: "0.7rem" }}
                            >
                              {/*{leadMessage?.updated_at &&
                              moment
                                .utc(leadMessage.updated_at)
                                .format("YYYY-MM-DD HH:mm:ss")}*/}

                              {leadMessage?.updated_at &&
                                moment(leadMessage.updated_at).calendar(null, {
                                  // when the date is closer, specify custom values
                                  lastWeek: "[Last] dddd LT",
                                  lastDay: "[Yesterday] LT",
                                  sameDay: "[Today] LT",
                                  nextDay: "[Tomorrow]",
                                  nextWeek: "dddd",
                                  // when the date is further away, use from-now functionality
                                  sameElse: function () {
                                    return "M/D/YYYY LT";
                                  },
                                })}
                            </div>
                          </OverlayTrigger>
                        </div>

                        <div className="py-2 py-md-0" />

                        <div className="d-flex justify-content-around justify-content-md-end">
                          <div className="d-flex flex-column justify-content-center align-items-center px-2">
                            <small style={{ fontSize: "0.7rem" }}>Clicks</small>
                            <div style={{ fontSize: "0.7rem" }}>
                              {leadMessage?.clicks || 0}
                            </div>
                          </div>
                          <div className="d-flex flex-column justify-content-center align-items-center px-2">
                            <small style={{ fontSize: "0.7rem" }}>Opens</small>
                            <div style={{ fontSize: "0.7rem" }}>
                              {leadMessage?.views || 0}
                            </div>
                          </div>
                          <div className="d-flex flex-column justify-content-center align-items-center px-2">
                            <small style={{ fontSize: "0.7rem" }}>
                              Replies
                            </small>
                            <div style={{ fontSize: "0.7rem" }}>
                              {leadMessage?.replies || 0}
                            </div>
                          </div>
                        </div>

                        <div className="py-2 py-md-0" />

                        <button
                          className="btn btn-falcon-default mt-sm-3 btn-sm"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapseExample${
                            leadMessage?.id || 0
                          }`}
                          aria-expanded="false"
                          aria-controls="collapseExample"
                          onClick={(e) => handleViewHideMsg(e)}
                          style={{ fontSize: "0.7rem" }}
                        >
                          View Message
                        </button>

                        {profile?.is_staff && (
                          <>
                            <DeleteMessage
                              id={Number(leadMessage?.id) || 0}
                              onLoadApi={getMessages}
                            />
                            <HideMessage
                              id={Number(leadMessage?.id) || 0}
                              onLoadApi={getMessages}
                            />
                          </>
                        )}
                      </div>

                      <div
                        className="collapse"
                        id={`collapseExample${leadMessage?.id || 0}`}
                      >
                        <div className="clearfix">
                          <textarea
                            className="form-control border rounded bg-light"
                            rows={10}
                            readOnly
                            defaultValue={leadMessage?.description || ""}
                            style={{ fontSize: "0.8rem" }}
                          />

                          <div style={{ height: 5 }} />

                          {leadShow?.status === "acccepted" ||
                          leadShow?.status === "accepted" ? (
                            <div>
                              {leadMessage?.client?.bccAddress ? (
                                <button
                                  className="btn btn-sm btn-falcon-default d-block"
                                  type="button"
                                  style={{ fontSize: "0.7rem" }}
                                >
                                  <a
                                    className="mailtoui"
                                    href={`mailto:${
                                      leadMessage?.recipient_email_address
                                    }?${
                                      leadMessage?.client?.bccAddress
                                        ? "bcc=" +
                                          leadMessage?.client?.bccAddress
                                        : ""
                                    }${
                                      leadMessage?.ccEmail
                                        ? "&cc=" + leadMessage.ccEmail
                                        : ""
                                    }&subject=${
                                      leadMessage?.subject
                                    }&body=${encodeURIComponent(
                                      sliceString(
                                        leadMessage?.description || "",
                                        1800
                                      )
                                    )}....`}
                                    style={{ textDecoration: "none" }}
                                  >
                                    Reply In-Line
                                  </a>
                                </button>
                              ) : (
                                <button
                                  className="btn btn-sm btn-falcon-default d-block"
                                  type="button"
                                  style={{ fontSize: "0.7rem" }}
                                >
                                  <a
                                    className="mailtoui"
                                    href={`mailto:${
                                      leadMessage?.recipient_email_address
                                    }?subject=${leadMessage?.subject}${
                                      leadMessage?.ccEmail
                                        ? "&cc=" + leadMessage?.ccEmail
                                        : ""
                                    }&body=${encodeURIComponent(
                                      sliceString(
                                        leadMessage?.description || "",
                                        1800
                                      )
                                    )}....`}
                                    style={{ textDecoration: "none" }}
                                  >
                                    Reply In-Line
                                  </a>
                                </button>
                              )}
                            </div>
                          ) : (
                            <div className="mb-sm-4">
                              <button
                                className="btn btn-falcon-default d-block"
                                type="button"
                                onClick={() => handleShow()}
                              >
                                Accept Lead to Reply
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="text-muted">No messages yet.</p>
            ))}

          {isOnPageLoading &&
            _.map([...Array(5)], (leadMessage: any, index: number) => {
              return (
                <div key={index}>
                  <div className="card card shadow-none p-sm-4 mb-3">
                    <div className="p-2">
                      <ContentLoader
                        speed={2}
                        width="100%"
                        height={70}
                        backgroundColor="#d9d9d9"
                        foregroundColor="#ededed"
                      >
                        <rect
                          x="8"
                          y="0"
                          rx="4"
                          ry="4"
                          width="80%"
                          height="19"
                        />
                        <rect
                          x="8"
                          y="38"
                          rx="4"
                          ry="4"
                          width="139"
                          height="38"
                        />
                      </ContentLoader>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>

        <Row>
          <Col lg={12} aria-disabled={isOnPageLoading}>
            {total > 5 && (
              <RcPagination
                filters={filters}
                setFilters={setFilters}
                totalItems={total}
              />
            )}
          </Col>
        </Row>
      </div>

      {renderAcceptedModal()}
    </div>
  );
};

export default Messages;
