import http from "./axios";

export const getOnboardingStatus = async () => {
  return await http.get(`/api/onboarding_status/`);
};

export const getClientFile = async (file_type: string) => {
  return await http.get(`/api/client_files/?file_type=${file_type}`);
};

export const postClientFile = async (data: any) => {
  return await http.post(`/api/client_files/`, data);
};

export const editClientFile = async (id: any, file_type: any, data: any) => {
  return await http.put(
    `/api/client_files/${id}/?file_type=${file_type}`,
    data
  );
};

export const confirmAddRecord = async (id: any) => {
  return await http.put(`/api/clients/${id}/`, { dns_review_ready: true });
};

export const deleteClientFile = async (id: any, file_type: string) => {
  return await http.delete(`/api/client_files/${id}/?file_type=${file_type}`);
};
