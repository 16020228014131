import { useEffect, useState } from "react";
import _ from "lodash";
import { Modal } from "react-bootstrap";
import http from "../../../service/axios";
import ContentLoader from "react-content-loader";
import { devLogError } from "../../../helpers/utils";

const News = () => {
  const [articles, setArticles] = useState<any[]>([]);
  const [show, setShow] = useState<string | null>(null);
  const [isOnPageLoading, setIsOnPageLoading] = useState<boolean>(true);

  useEffect(() => {
    let isMounted = true;
    setIsOnPageLoading(true);

    void getDashboard();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleClose = () => setShow(null);
  const handleShow = (slug: string) => setShow(slug);

  const getDashboard = async () => {
    setIsOnPageLoading(true);
    try {
      let response: any = await http.get(`/api/dashboard/`);
      // devLog("test news response", response.data.news);
      if (response.data?.news) {
        setArticles(response.data.news);

        setTimeout(() => setIsOnPageLoading(false));
      }
    } catch (e: any) {
      devLogError(e.response);
    }
  };

  return (
    <div className="latest-news">
      <div className="card mb-3 pb-2">
        <div className="card-header" style={{ float: "left" }}>
          <h5 className="my-2">Latest News</h5>
        </div>

        <div className="py-3 py-md-4 px-3 px-md-4">
          <div className="row mx-0">
            {!isOnPageLoading &&
              (articles?.length > 0 ? (
                _.map(articles, (article: any, index: number) => {
                  return (
                    <div className="col-sm-6 col-lg-4 mb-3 px-sm-2" key={index}>
                      <div className="card shadow-none h-100">
                        <div className="card-body">
                          <div className="card-title">{article.title}</div>
                        </div>

                        <button
                          className="btn btn-falcon-link d-block outline-none shadow-none"
                          type="button"
                          role="button"
                          data-toggle="modal"
                          data-target={`#exampleModal${article?.slug || ""}`}
                          onClick={() => handleShow(article?.slug || "")}
                        >
                          Click to Read
                        </button>

                        <Modal
                          show={show === article?.slug}
                          onHide={handleClose}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>
                              {article?.title}{" "}
                              {article?.provider
                                ? `(${article?.provider})`
                                : ""}
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <p>{article?.summary}</p>
                          </Modal.Body>
                          <Modal.Footer>
                            <button
                              className="btn btn-secondary btn-sm"
                              type="button"
                              data-dismiss="modal"
                              onClick={handleClose}
                            >
                              Close
                            </button>
                            <a href={article?.redirector_link || "#"}>
                              <button
                                className="btn btn-primary btn-sm"
                                type="button"
                              >
                                View Source
                              </button>
                            </a>
                          </Modal.Footer>
                        </Modal>

                        {/*<div
                                      className="modal fade"
                                      id={`exampleModal${article?.slug || ""}`}
                                      tabIndex={-1}
                                      role="dialog"
                                      // aria-labelledby="exampleModalLabel"
                                      // aria-hidden="true"
                                    >
                                      <div className="modal-dialog">
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <h5
                                              className="modal-title"
                                              id="exampleModalLabel"
                                            >
                                              {article?.title} ({article?.provider})
                                            </h5>
                                            <button
                                              className="close"
                                              type="button"
                                              data-dismiss="modal"
                                              aria-label="Close"
                                            >
                                              <span
                                                className="font-weight-light"
                                                aria-hidden="true"
                                              >
                                                &times;
                                              </span>
                                            </button>
                                          </div>
                                          <div className="modal-body">
                                            <p>{article?.summary}</p>
                                          </div>
                                          <div className="modal-footer">
                                            <button
                                              className="btn btn-secondary btn-sm"
                                              type="button"
                                              data-dismiss="modal"
                                            >
                                              Close
                                            </button>
                                            <a href={article?.redirector_link || "#"}>
                                              <button
                                                className="btn btn-primary btn-sm"
                                                type="button"
                                              >
                                                View Source
                                              </button>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>*/}
                      </div>
                    </div>
                  );
                })
              ) : (
                <>
                  <div className="col-md-12" />
                  <p> No news yet... </p>
                </>
              ))}

            {isOnPageLoading &&
              _.map([...Array(1)], (leadMessage: any, index: number) => {
                return (
                  <div key={index}>
                    <div className="card card shadow-none p-sm-4 mb-sm-3">
                      <ContentLoader
                        speed={2}
                        width="100%"
                        height={70}
                        backgroundColor="#d9d9d9"
                        foregroundColor="#ededed"
                      >
                        <rect
                          x="8"
                          y="0"
                          rx="4"
                          ry="4"
                          width="80%"
                          height="19"
                        />
                        <rect
                          x="8"
                          y="38"
                          rx="4"
                          ry="4"
                          width="139"
                          height="38"
                        />
                      </ContentLoader>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        {/*{!isOnPageLoading && articles && (*/}
        {/*  <div className="col-sm-12 px-sm-2">*/}
        {/*    <button*/}
        {/*      id="refresh_news"*/}
        {/*      style={{ width: "100%" }}*/}
        {/*      //data-leadshow={leadShow.id}*/}
        {/*      className="btn btn-falcon-default action-button d-block"*/}
        {/*    >*/}
        {/*      Get More News*/}
        {/*    </button>*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
    </div>
  );
};

export default News;
