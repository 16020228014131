import _ from "lodash";
import {
  // faEnvelope,
  faEye,
  // faNotEqual,
  // faNoteSticky,
  // faStickyNote,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface LeadNotesInterface {
  lead: any;
}

const LeadNotes = ({ lead }: LeadNotesInterface) => {
  return (
    <div id="attachments">
      {lead.notes?.length > 0 && (
        <div className="card">
          <div className="card-header bg-light">
            <h5 className="mb-0">Notes from the Union Team</h5>
          </div>
          {/*{%if notes%}
                {%for note in notes%}
                <div className="card-body fs--1 p-0">

                  <a className="border-bottom-0 notification rounded-0 border-x-0 border border-300"
                     href="/media/{{leadShow.attachments}}">
                    <div className="notification-avatar">
                      <div className="avatar avatar-xl mr-3">
                        <div className="avatar-emoji rounded-circle "><span className="far fa-eye"></span></div>
                      </div>
                    </div>
                    <div>
                      {{note.note}}
                    </div>
                    <!-- <div class="notification-body">
                      <p class="mb-1"><strong>{{leadShow.firstname}} {{leadShow.lastname}}</strong> visited <strong>{{visit.title}}</strong></p>
                      <span class="notification-time">{{visit.date}}</span>

                    </div> -->
                  </a>


                </div>
                {%endfor%}
                {%else%}
                {%endif%}*/}

          {_.map(lead.notes, (note: any, index: number) => {
            return (
              <div className="card-body fs--1 p-0" key={index}>
                <a
                  className="border-bottom-0 notification rounded-0 border-x-0 border border-300"
                  href={`/media/${lead.attachments}`}
                >
                  <div className="notification-avatar">
                    <div className="avatar avatar-xl mr-3">
                      <div className="avatar-emoji rounded-circle ">
                        {/*<span className="fa fa-eye" />*/}
                        <FontAwesomeIcon
                          icon={faEye}
                          size="xs"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </div>
                  <div>{note.note}</div>
                  {/*<div className="notification-body">
                                      <p className="mb-1">
                                        <strong>
                                          {leadShow.firstname} {leadShow.lastname}
                                        </strong>{" "}
                                        visited <strong>{visit.title}</strong>
                                      </p>
                                      <span className="notification-time">{visit.date}</span>
                                    </div>*/}
                </a>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default LeadNotes;
