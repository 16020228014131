// import axios from "axios";

// import config from "../config";
// import authHeader from "./auth-header";
import http from "./axios";
// const API_URL = config.service.BASE_URL;

/*const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

const getUserBoard = () => {
  // @ts-ignore
  return axios.get(API_URL + "user", { headers: authHeader() });
};

const getModeratorBoard = () => {
  // @ts-ignore
  return axios.get(API_URL + "mod", { headers: authHeader() });
};

const getAdminBoard = () => {
  // @ts-ignore
  return axios.get(API_URL + "admin", { headers: authHeader() });
};*/

export const getUserProfile = async () => {
  return await http.get(`/api/user_profile/`);
};

export const updateUserProfile = async (data: any) => {
  return await http.put(`/api/user_status/`, data);
};

export const updateSwitchClientService = async (client_id: any) => {
  return await http.put(`/api/user_client/`, { client: client_id });
};

export const updateUserStatus = async (data: any) => {
  return await http.put(`/api/user_status/`, data);
};
