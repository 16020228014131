import { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

// context
import { AppContext } from "./context/contextLib";
import { useMediaQuery } from "react-responsive";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";

// routes
import RenderRoutes from "routes/RenderRoutes";

// styles
import "./App.css";
import { useAppHook } from "./useAppHook";
import { updateUserStatus } from "./service/user.service";
import { getUserProfile } from "./service/user.service";
import { devLog, devLogError } from "./helpers/utils";

// components
const Nav = lazy(() => import("./layouts/nav/Nav"));
const Footer = lazy(() => import("layouts/footer/Footer"));

function App() {
  const { stepsValue, run, steps, setTourState, messages } = useAppHook();
  let _profileStorage: any = localStorage.getItem("profile") || null;
  const [onboardingEvents, setOnboardingEvents] = useState<any>({
    payment_received: "",
    launch_date: "",
  });

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (_profileStorage) {
        if (JSON.parse(_profileStorage)?.doneTour) {
          setTourState({ run: false });
        }
      }
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    devLog("status==", status);
    devLog("type==", type);

    if (finishedStatuses.includes(status)) {
      setTourState({ run: false });
    }

    if (status.includes("finished")) {
      if (localStorage.getItem("profile")) {
        // @ts-ignore
        let _profileStorage = JSON.parse(localStorage.getItem("profile"));
        if (!_profileStorage?.doneTour) {
          void handleProductTourEnd();
        }
      }
    }
  };

  const handleProductTourEnd = async () => {
    try {
      let res = await updateUserStatus({ doneTour: true });
      devLog("res", res);
      if (res?.data) {
        // update the localStorage profile and user_profile
        if (localStorage.getItem("profile")) {
          // @ts-ignore
          let _profileStorage = JSON.parse(localStorage.getItem("profile"));
          _profileStorage.doneTour = true;
          localStorage.setItem("profile", JSON.stringify(_profileStorage));
        }

        // remove the beacon icons
        setTourState({ run: false });
      }
    } catch (e: any) {
      devLogError(e.response);
    }
  };
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  let navigate = useNavigate();
  let _isAuthStrg: any = localStorage.getItem("is_authenticated") || null;

  const [userProfile, setUserProfile] = useState<any>({});

  useEffect(() => {
    let isMounted = true;

    devLog("test window.location.navigate", window.location.pathname);

    if (isMounted) {
      if (_isAuthStrg === "ok") {
        void onLoad();
      }
    }

    return () => {
      isMounted = false;
    };
  }, [navigate]);

  const onLoad = useCallback(async () => {
    try {
      let response = await getUserProfile();
      if (response?.data) {
        localStorage.setItem("profile", JSON.stringify(response.data));
        setUserProfile(response.data);
        return;
      }
    } catch (e: any) {
      devLogError(e.response);
      return;
    }
  }, []);

  return (
    <>
      <div className="App">
        {/*@ts-ignore*/}
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          // hideBackButton
          // hideCloseButton
          run={run}
          scrollToFirstStep
          // showProgress
          // showSkipButton
          steps={steps}
          styles={{
            options: {
              zIndex: 900,
              primaryColor: "#ce4c22",
            },
            overlay: {
              maxHeight: "100%",
              height: "100%",
            },
          }}
          locale={messages}
          disableScrolling={true}
        />

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <AppContext.Provider
          value={{
            userProfile,
            setUserProfile,
            setTourState,
            onboardingEvents,
            setOnboardingEvents,
          }}
        >
          <Suspense fallback={<div />}>
            <Nav />
          </Suspense>

          <main className="main" id="top">
            <div
              className={`${
                isTabletOrMobile ? "container-fluid" : "container"
              } pt-md-3 mb-md-5`}
              data-layout="container"
            >
              <RenderRoutes />
            </div>
          </main>

          <Suspense fallback={<div />}>
            <Footer />
          </Suspense>
        </AppContext.Provider>

        {/*scripts*/}
        <script src="https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js" />
        {/*<script>
                            WebFont.load({
                                google: {
                                    families: ['Bitter', 'Montserrat', 'Bebas+Neue']
                                }
                            });
                          </script>*/}
      </div>
    </>
  );
}

export default App;
