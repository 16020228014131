import Pagination from "rc-pagination";

interface RcPaginationProps {
  filters: any;
  setFilters: any;
  totalItems: number;
  setIsTableLoading?: any;
}

export const RcPagination: React.FunctionComponent<RcPaginationProps> = ({
  filters = {
    offset: 0,
    currentPage: 1,
    limit: 10,
  },
  setFilters,
  totalItems = 10,
  setIsTableLoading,
}: RcPaginationProps) => {
  let myLocale = {
    // Options.jsx
    items_per_page: "/ page",
    jump_to: "Go to",
    jump_to_confirm: "confirm",
    page: "",

    // Pagination.jsx
    prev_page: "Previous",
    next_page: "Next",
    prev_5: "Previous 5 Pages",
    next_5: "Next 5 Pages",
    prev_3: "Previous 3 Pages",
    next_3: "Next 3 Pages",
  };

  const paginationOnChange = (page: number) => {
    if (setIsTableLoading) setIsTableLoading(true);
    if (setFilters) {
      setFilters({
        offset: page ? (page - 1) * filters.limit : 0,
        currentPage: page,
        limit: filters.limit,
      });
    }
    if (setIsTableLoading) setTimeout(() => setIsTableLoading(true), 100);
  };

  return (
    //@ts-ignore
    <Pagination
      onChange={paginationOnChange}
      current={filters.currentPage || 1}
      showTotal={(total, range) => {
        return (
          <span className="results-count-text">
            <span className="results-count-start">
              {range[0] > 0 ? range[0] : 0}
            </span>
            –<span className="results-count-end">{range[1]}</span> of{" "}
            <span className="results-count-total results-count-link">
              {total}
            </span>
          </span>
        );
      }}
      total={totalItems || 0}
      defaultCurrent={1}
      pageSize={filters.limit || 10}
      // showLessItems
      locale={myLocale}
      // prevIcon={
      //   <BtnBackGreyIcon
      //     className="prev-icon"
      //     style={{ width: 13, marginTop: '-4px' }}
      //   />
      // }
      // nextIcon={
      //   <BtnBackGreyIcon
      //     className="next-icon"
      //     style={{
      //       width: 13,
      //       marginTop: '-4px',
      //       transform: 'rotateY(180deg)',
      //       WebkitTransform: 'rotateY(180deg)',
      //       OTransform: 'rotateY(180deg)',
      //     }}
      //   />
      // }
      // jumpPrevIcon={<DropdownIcon className="three-dots-icon" />}
      // jumpNextIcon={<DropdownIcon className="three-dots-icon" />}
    />
  );
};
